import Vue from 'vue'
import axios from 'axios'
import { encode, decode } from 'js-base64'

const tokenKey = '+%-MiB!bM$UtdRpIurlHt@\'2J,wc(4',
	refreshKey = 'V2nFRJ+LNob3D]xCm0rk{.+^vFQ\'P\'',
	userKey = ',fVrW8-Va856U^]\'5qxJU3x0CuW^U5'

export default {
	isLogin() {
		return postApi( 'member/checkRole' );
	},
	login(googleUser) {
		if( googleUser.hd != 'socialbean.co.kr' )
			return alert.w( '소셜빈 이메일만 로그인 가능합니다' )

		return postApi( 'member/login', { code: googleUser.code } ).then( v => {
			localStorage.setItem( tokenKey, v.access_token )
			localStorage.setItem( refreshKey, v.refresh_token )
			delete v.access_token
			delete v.refresh_token

			v.profileImage = v.picture

			localStorage.setItem( userKey, encode( JSON.stringify( v ) ) )
			layout.userUpdateFl++
			root.$acl.change( v.roleGroup )
		} )
			/*.catch( e => {
				errorExcution( e, googleUser )
				throw new Error( e )
			} )*/
	},
	logout() {
		postApi( 'member/logout' ).then( v => page( 'login' ) )
		l('로그아웃 처리')
		//라우터 이동
		localStorage.setItem(tokenKey, '')
		localStorage.setItem(refreshKey, '')
		localStorage.setItem(userKey, '')
		layout.userUpdateFl++
		root.$acl.change(rule.notLogin)
		page('login')
		layout.isFullPage = true
		return Promise.reject('')
	},

	getToken() {
		return localStorage.getItem(tokenKey)
	},
	refreshToken() {
		const refreshToken = localStorage.getItem(refreshKey)
		if( refreshToken )
			return postApi( 'member/refreshToken', { refreshToken } ).then( v => {
				localStorage.setItem( tokenKey, v )
				return v
			} )
				.catch( e => {
					l( 'refresh 실패' )
					l( e )
					return this.logout()
				} )
		return this.logout()
	},

	getUserInfo() {
		const userInfo = localStorage.getItem(userKey)
		return userInfo ? JSON.parse(decode(userInfo)) : false
	},
}

function errorExcution(e, requestData) {
	e = e.response && e.response.data && e.response.data.code ? e.response.data.code : ''
	switch (e) {
		case 'NOT_EXIST':
			confirm('가입한 적 없는 계정입니다. 가입을 진행하시겠습니까?', () => {
				axios
					.post(apiUrl + '/api/auth/oauth/signup', requestData, { withCredentials: true })
					.then(() => {
						alert.s('관리자에게 가입이 요청되었습니다.\r\n관리자 승인 후 로그인 할 수 있습니다.')
					})
					.catch(e => {
						errorExcution(e)
					})
			})
			break
		case 4001:
		case 'WAIT':
			//아니.. 여기도 파편화가...
			alert.w('이미 신청한 사용자입니다.\r\n관리자 승인 후 로그인 할 수 있습니다.')
			break
		default:
			alert.w('요청이 실패했습니다. 반복시 관리자에게 문의해주세요.')
	}
}
