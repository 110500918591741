//부모에 들어갈 수 있는 값. *는 필수값
//file, name*, icon*, admin, hide, meta, appendUrl, parent, sub
//아이콘 정보 : https://bootstrap-vue.org/docs/icons#icons-1
//sub에 들어갈 수 있는 값. *는 필수값
//file*, name*, meta, appendUrl
const adminOnly = 'adminOnly',
	moreThanAccounting = 'moreThanAccounting',
	moreThanMember = 'moreThanMember',
	moreThanDelivery = 'moreThanDelivery',
	moreThanHottistManager = 'moreThanHottistManager',
	notLogin = 'notLogin'

const items = [
	{
		file: 'home',
		name: 'Home',
		icon: 'house',
		rule: moreThanDelivery,
	},
	{
		file: 'login',
		name: 'Login',
		routerOnly: 1,
		rule: notLogin,
	},
	{
		name: '관련 상품 가격 정보',
		icon: 'box-seam',
		rule: moreThanDelivery,
		sub: [
			{
				file: 'relateProductList',
				name: '관련 상품 가격 관리',
			},
			{
				file: 'relateProductAdd',
				name: '관련 상품 가격 등록',
				appendUrl: '/:relateProductSeq',
			},
		],
	},
]
const getRouterMenu = (v, p = false) => {
		let url = false,
			name = '',
			rule = moreThanMember
		if (v.file) {
			if (!v.url) {
				url = upToDash(v.file)
				name = v.file
			} else {
				url = upToDash(v.url)
				name = v.url
			}
		}
		if (v.rule) rule = v.rule
		else if (p && p.rule) rule = p.rule

		let res = {
			meta: {
				breadcrumb: p ? [{ text: p.name }] : [],
				pageTitle: v.name,
				rule,
				exp: v.exp,
			},
		}
		res.meta.breadcrumb.push({ text: v.name, active: true })
		//to

		if (v.meta) res.meta = { ...res.meta, ...v.meta }

		if (v.parent) res.meta.parent = v.parent

		if (url) {
			let path = '/' + url
			if (v.appendUrl) {
				if (!v.appendUrl.startsWith('/')) path += '/'

				path += v.appendUrl
				if (v.appendUrl.indexOf(':') != -1) {
					path += '?'
					res.props = true
				}
			}
			res = {
				...res,
				path,
				name,
				component: () => import('pages/main/' + v.file),
			}
		}
		return res
	},
	getNavMenu = (v, p = false) => {
		if (!v.routerOnly) {
			let url = false,
				res = { title: v.name, expand: false },
				rule = moreThanMember
			if (v.file) {
				//url = upToDash(v.file)
				res.route = '/' + upToDash(v.file)
				if (v.appendUrl) {
					if (v.appendUrl.indexOf(':') == -1) res.route += v.appendUrl
				}
				//인자로 받는 값이 아니라면 뒤에 추가
				res.parent = v.file
			}

			if (!p) {
				if (v.icon) res.icon = v.icon
				if (v.file) res.parent = v.file
			}

			if (v.rule) rule = v.rule
			else if (p && p.rule) rule = p.rule
			res.rule = rule

			return res
		}
		return false
	}

export default (isRouter = true) => {
	const res = []
	items.map(v => {
		const pMenu = isRouter ? getRouterMenu(v) : getNavMenu(v)
		if (pMenu) {
			if (v.sub) {
				//서브 메뉴가 있다면
				if (isRouter)
					//라우터에는 말단 페이지만 등록되기 때문에 서브 페이지를 가지는 부모의 정보는 등록되지 않는다
					v.sub.map(s => {
						res.push(getRouterMenu(s, v))
					})
				else if (!v.hide) {
					//메뉴는 부모에게 submenu 배열로 들어감
					pMenu.children = v.sub.filter(v => !(v.parent || v.routerOnly)).map(s => getNavMenu(s, v))
					res.push(pMenu)
				}
			} else if (isRouter || !v.hide) res.push(pMenu)
		}
	})
	if (isRouter) res.push({ path: '*', redirect: '/login' })
	if (env.server != 'partner' || env.appMode != 'prod') {
		//파트너센터 상용 서버가 아닐 때만 로그 찍기
		l(isRouter ? '--------router--------' : '--------menu--------')
		l(res)
	}
	return res
}
