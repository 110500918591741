//환경변수 잡아주기
window.env = process.env
env.isPartner = env.isPartner == 'true'
Object.keys(env.url).map(k => {
	window[k] = env.url[k]
})

window.rule = {
	admin: 'ROLE_ADMIN',
	accounting: 'ROLE_ACCOUNTING',
	hottistManager: 'ROLE_HOTTIST_MANAGER',
	manager: 'ROLE_MANAGEMENT',
	member: 'ROLE_MEMBER',
	delivery: 'ROLE_DELIVERY',
	login: 'ROLE_LOGIN',
}

//공통 함수들 입력
const logStyle = (data, depth = 1) => {
	//internal에서 데이터를 내려주면 객체들이 Observer 형태로 와서 클릭하기 전까지는 값을 확인할 수 없거나 프로토타입들이 덕지덕지 붙는다. 그리고 그냥 console.log로 찍으면 이후 변경된 값이 반영되어서 console에 나타난다. 해당 부분을 해결해서 개발자 도구에서 보기 쉽게 만듦
	if (typeof data == 'object' && data !== null) {
		const constName = data?.constructor?.name ?? ''
		if (constName.indexOf('Event') == -1) {
			switch (constName) {
				case 'Vue':
				case 'VueComponent':
				case 'Window':
					//이 항목들은 자식까지 다 바꾸려고 하면 무한 재귀호출이 일어난다
					return data
				default:
					const isArray = Array.isArray(data)
					let len = isArray ? data.length : 0
					data = Object.assign({}, data)

					Object.keys(data).map(k => {
						data[k] = logStyle(data[k], depth + 1)
					})
					if (isArray) {
						data.length = len
						data = Array.from(data)
					}
					data.__proto__ = null
			}
		} else {
			//이벤트의 경우
			class ev {}

			let clone = new ev()
			for (let p in data) {
				let d = Object.getOwnPropertyDescriptor(data, p)
				if (d && (d.get || d.set)) Object.defineProperty(clone, p, d)
				else clone[p] = data[p]
			}
			Object.setPrototypeOf(clone, data)
			return clone
		}
	}
	return data
}

window.l = function () {
	const args = [],
		ct = arguments.length
	for (let i = 0; i < ct; i++) args.push(logStyle(arguments[i]))
	console.log.call(null, ...args)
	//return args
}

window.isSuccess = function( arr ) {
	if( arr === null || arr?.constructor?.name != 'Array' ) return false
	return arr?.success === true
}

function arrayInsert(arr, idx, val) {
	//배열의 idx번째에 val 끼워 넣기. splice로 하면 idx가 배열 크기보다 크거나 같을 경우 제대로 작동하지 않는다
	if (isNum(idx) && val) {
		let res = [],
			ct = arr.length
		if (idx > ct) ct = idx
		for (var i = ct; i > idx; i--)
			//val이 들어갈 자리 만들기
			res[i] = arr[i - 1]
		res[idx] = val
		for (let i = idx - 1; i >= 0; i--) res[i] = arr[i]
		arr = res
		return res
	} else l('arrayInsert에 값이 잘못 들어옴', arguments)
	return arr
}

function callAddArg(func, arg, idx, val) {
	//arg의 idx번째에 val 값을 끼워 넣어 func를 호출한다.
	//val.func로 들어왔을 때 func( val, arg )로 바꿔주는 함수. prefixGetTime 부분 참조
	if (typeof func != 'function' || typeof arg != 'object') {
		l('callAddArg에 값이 잘못 들어옴', arguments)
		return false
	}
	if (isNum(idx) && val) arg = arrayInsert(Array.from(arg), idx, val)
	return func.apply(null, arg)
}

window.get_time = (
	y_m_d_h_mn_s_ms = 's',
	appendTime = 0,
	gutter_text = '-',
	timeVar = new Date(),
	yearLen = 4,
	fixLength = true
) => {
	//getTime으로 할 경우 Date에서 제공하는 함수랑 겹침
	//timeVar을 입력할 때는 무조건 yyyy-mm-dd  띄어쓰기 or T  hh:mm:ss 형식으로 입력할 것
	//appendTime가 1000보다 작으면 날짜로 취급하도록 수정.
	//fixLength는 월/일/시/분/초가 10보다 작을 때 0을 붙일지 안 붙일지
	let error = '',
		y,
		m,
		d,
		h,
		mm,
		s
	//형식이 안 맞거나 통일이 필요한 경우 값이 들어감
	if (typeof y_m_d_h_mn_s_ms == 'string') y_m_d_h_mn_s_ms = y_m_d_h_mn_s_ms.toLowerCase()
	else error = '잘못된 입력값입니다. [y_m_d_h_mn_s_ms]'

	if (!appendTime) appendTime = 0
	if (isNum(appendTime)) {
		if (Math.abs(appendTime) < 1000)
			//1000보다 적으면 day 수정으로 인식
			appendTime *= 86400000
		appendTime -= 32400000
		//타임존 맞춰주면서 +9시간 된 거 되돌리기 위해 - 9시간
	} else error = '잘못된 입력값입니다. [appendTime]'

	switch (timeVar.constructor.name) {
		case 'Number':
			//timestamp
			timeVar = new Date(timeVar)
		case 'Date':
			//new Date()
			y = timeVar.getFullYear()
			m = timeVar.getMonth() + 1
			d = timeVar.getDate()
			h = timeVar.getHours()
			mm = timeVar.getMinutes()
			s = timeVar.getSeconds()
			break
		case 'String':
			//년-월-일 시:분:초 or 년.월.일 시:분:초, 일까지는 필수.
			let str = timeVar.match(/\d+/g),
				ct = str.length
			if (ct >= 3) {
				y = str[0].substr(0, 4)
				m = str[1].substr(0, 2)
				d = str[2].substr(0, 2)
				h = str[3] ? str[3].substr(0, 2) : 0
				mm = str[4] ? str[4].substr(0, 2) : 0
				s = str[5] ? str[5].substr(0, 2) : 0
			} else {
				if (isNum(timeVar) && timeVar.length >= 8) {
					//'20201127'
					y = timeVar.substr(0, 4)
					m = timeVar.substr(4, 2)
					d = timeVar.substr(6, 2)
					h = timeVar.substr(8, 2)
					mm = timeVar.substr(10, 2)
					s = timeVar.substr(12, 2)

					if (!h) h = 0
					if (!mm) mm = 0
					if (!s) s = 0
				} else error = '잘못된 입력값입니다. [timeVar]'
			}
			break
	}

	if (error) {
		l(error, arguments)
		return
	}

	if (m < 10) m = '0' + m * 1
	if (d < 10) d = '0' + d * 1
	if (h < 10) h = '0' + h * 1
	if (mm < 10) mm = '0' + mm * 1
	if (s < 10) s = '0' + s * 1

	timeVar = y + '-' + m + '-' + d + 'T' + h + ':' + mm + ':' + s + 'Z'
	//아이폰 대응 + timezone 통일. UTC가 아니라 GMT가 되기 때문에 현재 시간에서 +9가 됨. appendTime을 이용해 -9시간 해줄 것

	let now = new Date(timeVar)
	if (appendTime) now.setTime(now.getTime() + appendTime)
	let res = ''
	switch (y_m_d_h_mn_s_ms) {
		case 'ms':
		case 'full':
			res = mili
		case '':
		case 's':
			s = now.getSeconds()
			if (fixLength && s < 10) s = '0' + s
			res = res ? s + '.' + res : s + ''
		case 'mn':
			mm = now.getMinutes()
			if (fixLength && mm < 10) mm = '0' + mm
			res = res ? mm + ':' + res : mm + ''
		case 'h':
			h = now.getHours()
			if (fixLength && h < 10) h = '0' + h
			res = res ? h + ':' + res : h + ''
		case 'd':
			d = now.getDate()
			if (fixLength && d < 10) d = '0' + d
			if (res) res = gutter_text == '한글' ? d + '일 ' + res : d + ' ' + res
			else res = gutter_text == '한글' ? d + '일' : d + ''
		case 'm':
			m = now.getMonth() + 1
			if (fixLength && m < 10) m = '0' + m
			if (res) res = gutter_text == '한글' ? m + '월 ' + res : m + gutter_text + res
			else res = gutter_text == '한글' ? m + '월' : m + ''
		case 'y':
			if (yearLen) {
				y = (now.getFullYear() + '').substr(-yearLen)
				if (res) res = gutter_text == '한글' ? y + '년 ' + res : y + gutter_text + res
				else res = gutter_text == '한글' ? y + '년' : y + ''
			}
			return res
		default:
			l('잘못된 입력값입니다. [y_m_d_h_mn_s_ms]', arguments)
	}
	return ''
}

function prefixGetTime() {
	//string.get_time, date.get_time을 get_time(...)으로 변환
	return callAddArg(get_time, arguments, 3, this)
	//this로 넘어오는 건 timeVar이고, get_time 함수에서 3번째 인자이므로 arguments 3번에 끼워 넣기
}

String.prototype.get_time = prefixGetTime
Date.prototype.get_time = prefixGetTime

window.getMonth = (now, add = 0, isIgnoreDate = false) => {
	//add만큼 달을 더해서 Date 객체 반환
	now = new Date(now)
	if (now == 'Invalid Date') now = new Date()
	if (!isNum(add)) {
		l('get_month 잘못된 add 입력')
		add = 0
	}
	return new Date(now.getFullYear(), now.getMonth() + add * 1, isIgnoreDate ? 1 : now.getDate())
}

window.comma = str => {
	//숫자에 1000단위로 ,를 붙임
	if (typeof str != 'string') str = JSON.stringify(str)
	while (/(\d+)(\d{3})/.test(str)) str = str.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
	return str
}

//=============유효성 검사 시작=============
window.isNum = val => {
	//숫자만으로 구성되었는지 검사. +도 배제, -는 포함
	return /^[\-\d]+$/g.test(val)
}

window.isFunc = val => {
	//개체가 함수인지 검사
	return typeof val == 'function'
}

window.isUrl = val => {
	//url 형식인지 검사
	if (typeof val != 'string') return false
	if (val.indexOf('http') == -1) val = 'https://' + val

	if (
		/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(
			val
		)
	)
		return true
	return false
}

window.isEmail = val => {
	//이메일 형식인지 검사
	return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/g.test(val)
}

window.checkValid = (arr, checkArr) => {
	//배열 단위 유효성 검사
	//arr은 검사할 배열, checkArr은 inf 기반. title, key, format이 필수. e는 옵션
	//format: num, url, text(notNull)
	const res = []
	checkArr.map(h => {
		if (!h.format) return
		let e = '',
			val = arr[h.key]?.value ?? arr[h.key]
		if (val === null) val = ''
		switch (h.format) {
			case 'phone':
				if (!isNum(val)) e = h.e ?? h.title + '이(가) 휴대폰 번호 형식이 아닙니다'
				break
			case 'num':
				if (!isNum(val)) e = h.e ?? h.title + '은(는) 숫자만 입력할 수 있습니다'
				break
			case 'url':
				if (!isUrl(val)) e = h.e ?? h.title + '이(가) url 주소 형식이 아닙니다'
				break
			case 'email':
				if (!isEmail(val)) e = h.e ?? h.title + '이(가) 이메일 주소 형식이 아닙니다'
				break
			default:
				if (!val.trim().length) e = h.e ?? h.title + '은(는) 필수값입니다'
		}
		if (e) res.push(e)
	})
	return res
}

function excel(position = root.$route.meta.pageTitle, condition = []) {
	//엑셀 다운로드 양식 데이터 클래스
	this.data = {
		adminSeq: layout.user.seqNo,
		adminName: layout.user.username,
		position,
		reqCondition: Array.isArray(condition) ? condition : [condition],
	}
	this.isDealExcel = 0
	//...와.. 파편화 진짜...
}
;['push', 'pop', 'map', 'splice', 'slice'].map(k => {
	excel.prototype[k] = function (val) {
		this.data.reqCondition[k](...arguments)
		return this
	}
})
excel.prototype.key = function (key, val) {
	//기본 입력. key: val
	this.push(`${key}: ${val}`)
	return this
}
excel.prototype.date = function (date1, date2, expText = '기간 선택', allText = '전체') {
	//날짜 입력. date1이 없으면 전체 기간 선택한 걸로 간주함
	this.key(expText, date1 ? `(${date1.get_time('d')}~${date2.get_time('d')})` : allText)
	return this
}
excel.prototype.dropdown = function (searchKeyName, keyOpts, expText = '검색 조건', allText = '통합 검색') {
	//드롭다운 리스트 입력.
	if (!searchKeyName || !keyOpts) return this

	const keyName = getDropdownValue(searchKeyName)
	let keyword = allText
	keyOpts.map(o => {
		if (o.value == keyName) keyword = o.label
	})
	this.key(expText, keyword)
	return this
}
excel.prototype.search = function (
	searchKeys,
	keyOpts,
	expText1 = '검색 조건',
	expText2 = '검색어',
	allText = '통합 검색'
) {
	//lastOpt의 검색 조건을 받아들여서 입력
	//searchKeys: [ { fieldName, value } ]
	if (!searchKeys || !keyOpts) return this

	let keyName = allText,
		fieldName = '',
		keyword = '',
		isAll = false

	if (Array.isArray(searchKeys)) {
		if (searchKeys.length > 1) {
			//통합검색
			fieldName = 1
			isAll = true
		}
		//키 선택해서 검색
		else fieldName = searchKeys[0].fieldName
		keyword = searchKeys[0].value
	} else {
		fieldName = searchKeys.key
		keyword = searchKeys.val
	}

	if (fieldName && keyword) {
		if (isAll) this.key(expText1, allText)
		else this.dropdown(fieldName, keyOpts, expText1, allText)
		this.key(expText2, keyword)
	}
	return this
}
excel.prototype.get = function () {
	//excelInfo 객체 and로 묶어서 반환
	return { ...this.data, reqCondition: this.data.reqCondition.filter(v => v).join(' and ') }
}
excel.prototype.getObj = function (ths = {}, opt = 'lastOpt') {
	//lastOpt에 excelInfo 넣어서 반환
	return addExcelData(ths, this, opt)
}

excel.prototype.go = function (ths, func = 'changePage', opt = 'lastOpt') {
	opt = ths[opt]
	let page = 1
	if (isNum(opt)) page = opt
	else if (opt && opt.paging && isNum(opt.paging.pageNo)) page = opt.paging.pageNo * 1 + 1
	return ths[func](page, this)
}

window.addExcelData = function (ths = {}, ex = false, opt = 'lastOpt') {
	const data = typeof opt != 'object' ? ths[opt] : opt
	if (typeof ex == 'object') {
		//....... 이따 통일 필요.. 파편화 너무한데..
		if (ex.isDealExcel) {
			data.isExcel = 0
			data.isExcelDownload = 0
			data.isDealExcel = 1
		} else {
			data.isExcel = 1
			data.isExcelDownload = 1
			data.isDealExcel = 0
		}
		data.excelInfo = ex.get()
	} else {
		data.isExcelDownload = 0
		data.isExcel = 0
		data.isDealExcel = 0
		delete data.excelInfo
	}
	return data
}
window.excel = excel

window.getDateValue = (selectedItem, isStart = true) => {
	return selectedItem ? `${selectedItem.get_time('d')}T${isStart ? '00:00:00' : '23:59:59'}` : ''
}

window.getDropdownValue = (selectedItem, isValue = true) => {
	//선택된 값이 있으면 { label, value } 형식으로 저장이 된다
	let res = ''
	if (typeof selectedItem == 'object') {
		if (selectedItem !== null && selectedItem.value !== '') res = isValue ? selectedItem.value : selectedItem.name
		else return ''
	} else if (selectedItem !== '') res = selectedItem
	if (selectedItem && typeof selectedItem.value != 'undefined') return selectedItem.value
	return res
}

window.getOptsLabel = (options, val, isAllowBlank = false) => {
	if (!isAllowBlank && (val == null || (!val && (typeof val == 'undefined' || val === null)))) return ''
	const res = getOptsFromValue(options, val)
	if (res.length) return res.label
	return ''
}

window.getOptsFromLabel = (options, label) => {
	const filter = options.filter(o => o.label === label),
		length = filter.length
	return filter.length ? { ...filter[0], length } : { length }
}

window.getOptsFromValue = (options, val) => {
	const isNumVal = isNum(val),
		filter = options.filter(o => {
			if (o.value === val) return true
			if (isNumVal && isNum(o.value) && val * 1 == o.value * 1) return true
			return false
		}),
		length = filter.length
	return filter.length ? { ...filter[0], length } : { length }
}

window.getSearchKey = (searchKeyOpts = [], value, operation = 'LIKE', andOr = 'or') => {
	if (typeof searchKeyOpts == 'string') searchKeyOpts = [{ value: searchKeyOpts }]
	else if (Array.isArray(searchKeyOpts) && searchKeyOpts.length && typeof searchKeyOpts[0] == 'string')
		//[ 'a', 'b' ]
		searchKeyOpts = searchKeyOpts.map(v => {
			return { value: v }
		})
	return searchKeyOpts.reduce((prev, current) => {
		if (current.value)
			prev.push({
				andOr,
				fieldName: current.value,
				value,
				operation: current.operation ? current.operation.toUpperCase() : operation,
			})
		return prev
	}, [])
}

window.reload = (ths, isParent = false, ex = false) => {
	if (ths) {
		const target = isParent ? ths.p : ths,
			opt = target ? target.lastOpt : ''

		if (opt && typeof target.changePage == 'function') {
			let pageKey = opt.paging ? 'paging' : 'pagingDto'
			target.changePage(opt[pageKey].pageNo * 1 + 1, ex)
		}
	}
}

window.getOrder = (fieldName, operation = 'desc') =>
	fieldName.split(',').map(v => {
		return { fieldName: v.trim(), operation }
	})

window.getPaging = (pageNo = 0, pageSize = 10) => {
	return { pageNo, pageSize }
}

window.getTempPaging = (list, totalCnt = 9999, currentPage = 0, totalPage = 1) => {
	return {
		list,
		paging: { totalCnt, currentPage, totalPage },
	}
}

window.upToDash = str => {
	//텍스트 중간에 대문자가 있으면 _ + 소문자로 변환
	let ct = str.length,
		res = str[0]
	//맨 처음 글자는 무시
	for (let i = 1; i < ct; i++) res += /[A-Z]/.test(str[i]) ? '_' + str[i].toLowerCase() : str[i]
	return res
}

window.copyVar = (val, i = 0) => {
	//변수 사본 만들기. ...으로 만든다고 해도 내부에 또 다른 객체가 있으면 제대로 사본이 만들어지지 않는다. depth 100까지
	if (typeof val == 'object' && val != null && i < 100) {
		if (Array.isArray(val)) return [...val].map(v => copyVar(v, i + 1))
		else {
			const res = {}
			Object.keys(val).map(k => (res[k] = copyVar(val[k], i + 1)))
			return res
		}
	}
	return val
}

window.makePromise = data => {
	return new Promise(resolve => {
		resolve(data)
	})
}

function execute_sort_arr(a, b) {
	//sort_arr을 보조하는 함수. sort_arr을 통해서만 사용할 것.

	//배열의 각 값을
	for (var i = 0; i < sort_arr_ct; i++) {
		current_sort_idx = i
		var tp_a = a[sort_arr_key[i]],
			tp_b = b[sort_arr_key[i]],
			res = -1

		if (!isNaN(tp_a) && !isNaN(tp_b))
			//둘 다 숫자인 경우
			res = ascdesc(tp_a * 1, tp_b * 1)
		//그 외는 문자로 취급
		else res = ascdesc((tp_a + '').toLowerCase(), (tp_b + '').toLowerCase())
		if (res != 0)
			//현재 루프의 노드가 a, b가 차이가 있을 경우 값 반환
			//차이가 없을 경우 다음 루프로 진행
			return res
	}
	//여기까지 왔다는 건 검사한 모든 노드가 같다는 것을 의미함
	return 0
}

let sort_arr_esc = []

function ascdesc(a, b) {
	//sort_arr을 보조하는 함수. sort_arr을 통해서만 사용할 것.
	var current_esc = true,
		current_idx = 0
	if (typeof current_sort_idx == 'number') current_idx = current_sort_idx

	if (typeof sort_arr_esc == 'undefined') current_esc = true
	else current_esc = sort_arr_esc[current_idx]

	if (typeof a == 'string') {
		//둘 중 하나라도 문자면
		if (current_esc) {
			if (a.length < b.length) return -1
			else if (a.length > b.length) return 1
			//길이순 정렬
			if (a > b) return 1
			else if (a < b) return -1
			return 0
			//크기순 정렬
		} else {
			if (a.length > b.length) return -1
			else if (a.length < b.length) return 1
			//길이순 정렬
			if (a < b) return 1
			else if (a > b) return -1
			return 0
			//크기순 정렬
		}
	} else {
		a *= 1
		b *= 1
		if (current_esc) {
			if (a > b) return 1
			else if (a < b) return -1
			return 0
		} else {
			if (a < b) return 1
			else if (a > b) return -1
			return 0
		}
	}
}

window.sort_arr = (arr, key, is_asc = true) => {
	//정렬할 배열과 키값 넘기기
	//배열은 이중 배열 형식으로 들어와야 함
	//ex) [ {'aa':1, 'bb':2}, {'aa':2, 'bb':1} ] 형식이며 컨트롤러 또는 모델에서 DB result()를 쓴 것을 받아오면 이 형식으로 나옴
	//key는 'aa' 또는 'bb' 처럼 단일 값만 하거나 ['aa', 'bb'] 처럼 다중 정렬도 가능함
	//is_asc는 오름차순/내림차순 설정으로, 건드리지 않는다면 오름차순. 숫자, 문자, 불리언, 배열도 대응. 숫자, 문자, 불리언으로 올 경우 모든 오름차순/내림차순이 해당 값에 맞게 동일하게 설정됨.
	//배열로 올 경우 키값의 순서에 맞춰서 오름차순/내림차순 적용. 단, 배열로 올 경우 가급적 불리언으로 넣을 것. 위 예시에서 [true, false]로 넘어올 경우 'aa' 키값은 오름차순, 'bb' 키값은 내림차순으로 정렬
	if (!Array.isArray(arr)) {
		trace(arguments, 'sort_arr의 배열이 제대로 입력되지 않음')
		return false
	} else {
		if (arr.length == 0) return arr
		if (typeof arr[0] != 'object') {
			trace(arguments, 'sort_arr의 배열이 제대로 입력되지 않음2')
			return false
		}

		var key_type = typeof key
		switch (key_type) {
			case 'string':
				key = [key]
			case 'object':
				sort_arr_key = key
				sort_arr_ct = sort_arr_key.length

				if (sort_arr_ct == 0) trace(arguments, 'sort_arr 키 배열 확인 필요. sort_arr_ct 개수가 0개')
				else {
					var esc_type = typeof is_asc,
						esc_all_set = -1

					switch (esc_type) {
						case 'undefined':
							esc_all_set = true
							break
						case 'boolean':
							esc_all_set = is_asc
							break
						case 'number':
							if (is_asc == 0) esc_all_set = false
							else esc_all_set = true
							break
						case 'string':
							is_asc = is_asc.trim()
							if (is_asc == 'false' || is_asc == 'False' || is_asc == 'FALSE') esc_all_set = false
							else esc_all_set = true
							break
						case 'object':
							if (is_asc.length != sort_arr_ct) {
								l('대상 배열과 오름차순 배열의 크기가 다릅니다. 전부 오름차순으로 대체됩니다.')
								esc_all_set = true
							}
							break
					}

					if (esc_all_set != -1) {
						sort_arr_esc = []
						for (var i = 0; i < sort_arr_ct; i++) sort_arr_esc[i] = esc_all_set
					} else sort_arr_esc = is_asc
					if (typeof arr.sort == 'undefined') arr = obj_to_arr(arr)
					arr.sort(execute_sort_arr)
				}
				break
			default:
				trace(arguments, 'sort_arr의 배열의 키값이 제대로 입력되지 않음 [string 또는 배열]')
		}
		return arr
	}
}

/*Array.prototype.sort_arr = function (key, is_asc) {
	return sort_arr(this, key, is_asc)
}*/

window.sel = (v, item, base = '', isDebug = false, ap1, ap2, ap3) => {
	//함수면 실행, 값이 있으면 값 반환. 값이 없으면 기본값 반환
	if (typeof v == 'function') {
		const res = v(item, ap1, ap2, ap3)
		if (isDebug) l('function', res)
		return res
	}
	if (typeof v != 'undefined' && v != null) {
		if (isDebug) l('value', v)
		return v.cont ?? v
	}
	if (isDebug) l('default', base)
	return base
}

window.getCookie = name => {
	//쿠키를 가져온다
	const val = document.cookie.match('(^|;) ?' + encodeURIComponent(name) + '=([^;]*)(;|$)')
	return !val ? '' : decodeURIComponent(val[2])
}

window.setCookie = (key = -1, val = -1, expire = 30) => {
	//쿠키를 등록한다
	if (typeof key != 'string') return false
	key = encodeURIComponent(key)
	if (typeof val != 'string') val = JSON.stringify(val)
	val = encodeURIComponent(val)
	if (!isNum(expire)) expire = 30
	if (expire < 10000)
		//10000이상이면 ms로 바로 적용
		expire *= 86400e4
	const maxAge = expire > 0 ? Math.floor(expire / 10000) : 0
	document.cookie = `${key}=${val}; expires=${expire}; max-age=${maxAge}; path=/;`
	//domain=.hott.kr
	return true
}

window.setOpt = (v, key, optKey, val = '', isSet = true, isReplace = true) => {
	//tb의 컬럼에 대해 옵션 형식이 필요할 때 옵션 형식을 설정해준다.. 일단 임시
	//v: 원본 객체, key: 옵션의 원본 키, optKey: 옵션의 하위 키, val: 하위 키에 대응하는 값, isSet: 등록할 건지 제거할 건지, isReplace: 하위 키에 이미 값이 있을 때 대체할 건지 추가할 건지
	//v: product, key: name, optKey: tdClass, val: text-red 이렇게 등록하면 product 객체의 nameOpt 키에 객체가 등록된다. 해당 객체는 tdClass 키를 가지고 그 값은 text-red이다
	if (typeof v != 'object' || typeof key != 'string' || typeof optKey != 'string') return false
	if (typeof v[key + 'Opt'] == 'object') {
		if (isSet) {
			if (v[key + 'Opt'][optKey]) {
				if (isReplace) v[key + 'Opt'][optKey] = val
				else if (v[key + 'Opt'][optKey].indexOf(val) == -1) v[key + 'Opt'][optKey] += ' ' + val
			} else v[key + 'Opt'][optKey] = val
		} else {
			if (v[key + 'Opt'][optKey])
				v[key + 'Opt'][optKey] = v[key + 'Opt'][optKey].replace(new RegExp(val, 'g'), '')
		}
	} else {
		if (isSet) {
			v[key + 'Opt'] = {}
			v[key + 'Opt'][optKey] = val
		}
	}
	return true
}

window.num = v => {
	//v값에서 숫자만 가져온다
	if (v == undefined || v == null) return 0
	v = v.toString().trim()
	const toNum = v.startsWith('-') ? -1 : 1
	return v.replace(/\D/g, '') * toNum
}

//v값을 숫자 형식으로 반환한다
window.numFormat = v => comma(num(v))
